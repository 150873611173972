/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const OrderMail = ({ inputs, wishlist }) => (
  <>
    <h2>Your Order</h2>
    <p>Hey {inputs.clientName},</p>
    <p>We successfully got your order.</p>

    <div>Here is your order:</div>
    {wishlist.items.map((item, i) => (
      <div key={i}>
        <div>
          {item.quantity}x {item.serviceName}
        </div>
      </div>
    ))}

    <p>
      <b>
        Total: {constants.services.currency}
        {wishlist.total}
      </b>
    </p>

    <div>Deliver to:</div>
    <div>Address: {inputs.address}</div>
    <div>City: {inputs.city}</div>
    <div>Postcode: {inputs.postcode}</div>

    <p>Kingest regards</p>
    <div>{constants.cust} Team</div>
  </>
);

export default OrderMail;
