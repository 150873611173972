///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomButton,
  Form,
  FormGroup,
  CustomInput,
  Spinner,
  TextButton,
} from '@ibiliaze/reactstrap';
import { loadStripe } from '@stripe/stripe-js';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { deleteItem, resetWishlist, putItem } from '../../actions/wishlist';
import { setAlert } from '../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveStripeEnv } from '../../utils/resolveEnv';
import constants from '../../utils/constants';
import sendEmail from '../../utils/sendEmail';
/////////////////////////////////////////////////////////////////////////////////TEMPLATES
import OrderMail from '../Templates/OrderMail';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import WishlistItem from './WishlistItem';
//////////////////////////////////////////////////////////////////////////////////////////

const stripePromise = loadStripe(resolveStripeEnv());

const Wishlist = ({ toggle, modal, wishlist, deleteItem, putItem, setAlert }) => {
  // State
  const [loading, setLoading] = useState();
  const [inputs, setInputs] = useState({
    clientName: '',
    email: '',
    phone: '',
    address: '',
    postcode: '',
    city: '',
    ppChecked: false,
  });

  // Navigate
  const navigate = useNavigate();

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // Components
  const table = (
    <div className='xs-text'>
      <Table borderless>
        <thead>
          <tr>
            <th>Service</th>
            <th>Price</th>
            <th>Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {wishlist.items.map((item, i) => (
            <tr key={i}>
              <WishlistItem
                currency={constants.services.currency}
                service={item}
                putItem={putItem}
                deleteItem={deleteItem}
              />
            </tr>
          ))}
          <tr>
            <th>Total</th>
            <th>
              {constants.services.currency}
              {wishlist.total}
            </th>
          </tr>
        </tbody>
      </Table>
    </div>
  );

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);

      await sendEmail({
        subject: `We successfully received your order`,
        html: <OrderMail inputs={inputs} wishlist={wishlist} />,
        to: [inputs.email],
        bcc: [constants.email2],
        successReply: `We successfully received your order`,
        setAlert,
        setLoading,
      });

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: wishlist.items.map(item => {
          return {
            price: item.id,
            quantity: Number(item.quantity),
          };
        }),
        mode: 'payment',
        cancelUrl: window.location.origin + '/failed-payment',
        successUrl: window.location.origin + '/success-payment',
        customerEmail: inputs.email,
      });

      if (error) {
        setLoading(false);
        setAlert(error, true, null, null);
      }
    } catch (e) {
      setLoading(false);
      setAlert(e.message, true, null, null);
    }
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>Wishlist</ModalHeader>
        <ModalBody>
          {table}
          <FormGroup>
            <CustomInput
              required
              name='clientName'
              placeholder='Name'
              value={inputs.clientName}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput required name='email' placeholder='Email' value={inputs.email} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <CustomInput
              required
              name='phone'
              type='tel'
              placeholder='Mobile'
              value={inputs.phone}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput
              required
              name='address'
              placeholder='Street'
              value={inputs.address}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput required name='city' placeholder='City' value={inputs.city} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <CustomInput
              required
              name='postcode'
              placeholder='Postcode'
              value={inputs.postcode}
              onChange={onInputsChange}
            />
          </FormGroup>
          <br />
          <CustomInput
            required
            type='checkbox'
            checked={inputs.ppChecked}
            onChange={e => setInputs(c => ({ ...c, ppChecked: e.target.checked }))}
          />{' '}
          I have read and agree to the{' '}
          <TextButton
            onClick={_ => {
              window.scrollTo({ top: 0 });
              navigate('/privacy-policy');
            }}
          >
            Privacy Policy,
          </TextButton>{' '}
          <TextButton
            onClick={_ => {
              window.scrollTo({ top: 0 });
              navigate('/terms-conditions');
            }}
          >
            Terms & Conditions,
          </TextButton>{' '}
          <TextButton
            onClick={_ => {
              window.scrollTo({ top: 0 });
              navigate('/return-policy');
            }}
          >
            Refund Policy
          </TextButton>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            type='submit'
            disabled={wishlist.items.length === 0 ? true : false || loading || !inputs.ppChecked}
            color='primary'
          >
            {loading ? <Spinner size='sm' /> : 'Checkout'}
          </CustomButton>
          <CustomButton onClick={toggle}>Close</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist });

export default connect(mapStateToProps, { deleteItem, putItem, resetWishlist, setAlert })(Wishlist);
