///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { CustomCard, MiniNav, Row, Col } from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postItem, deleteItem } from '../../actions/wishlist';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const Services = ({ postItem, deleteItem }) => {
  // onClick functions
  const onAddClick = service => {
    postItem({
      serviceName: service.serviceName,
      price: service.price,
      id: service.id,
      quantity: 1,
    });
  };

  const onRemoveClick = service => deleteItem(service.id);

  // JSX
  return (
    <div id='services-section' className='p-t-m p-b-m'>
      <h1 className='t-align-c'>Choose Your Favourites</h1>
      <MiniNav
        navlinks={constants.services.serviceGroups.map((serviceGroup, i) => ({
          title: serviceGroup.serviceGroupName,
          href: '#',
          onClick: _ => {
            scroll(document.getElementById(`services-${serviceGroup.serviceGroupName}-section`)?.offsetTop);
          },
        }))}
      />
      <section className='page'>
        {constants.services.serviceGroups.map((serviceGroup, i) => (
          <section key={i} className='section' id={`services-${serviceGroup.serviceGroupName}-section`}>
            <br />
            <h4 className='t-align-c'>{serviceGroup.serviceGroupName}</h4>
            <hr />
            <Row>
              {serviceGroup.serviceItems.map((service, i) => (
                <Col key={i} md={3}>
                  <CustomCard
                    imgSrc={service.imageSrc}
                    title={`${service.serviceName} ${service.icon}`}
                    text={`${constants.services.currency}${service.price}`}
                    hoverTitle={`Add to Basket ${service.icon}`}
                    clickedTitle={'Added - ' + service.serviceName}
                    onCardClickHandler={onAddClick}
                    onCardSecondClickHandler={onRemoveClick}
                    args={service}
                  />
                </Col>
              ))}
            </Row>
          </section>
        ))}
      </section>
    </div>
  );
};

export default connect(null, { postItem, deleteItem })(Services);
