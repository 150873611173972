/////////////////////////////////////////////////////////////////////////////////////UTILS
import { env } from './constants';
//////////////////////////////////////////////////////////////////////////////////////////

export const resolveHost = () => {
  /**
   * This function will determine if the app is running in PRODUCTION mode
   * If so, the origin URL will be https://<domain>.com.
   * Else, it will be http://192.168.0.131:5000 or http://localhost:5000.
   */

  return !!env.REACT_APP_ENV && !!env.REACT_APP_ENV.includes('production')
    ? env.REACT_APP_API_URL
    : env.REACT_APP_API_DEV_URL;
};

export const resolveStripeEnv = () => {
  return !!env.REACT_APP_ENV && !!env.REACT_APP_ENV.includes('production')
    ? env.REACT_APP_STRIPE_PUB_KEY
    : env.REACT_APP_STRIPE_PUB_DEV_KEY;
};
