export const env = process.env;

const constants = {
  cust: 'Faha Foods',
  custSm: 'faha foods',
  custJs: 'fahaFoods',
  slogan: 'Best Home-Made Food',
  phone1: '02089330710',
  phone2: '+447718216555',
  email1: 'fahafoods1@gmail.com',
  email2: 'ibiorders101@gmail.com',
  hqAddr: 'Essex',
  chatPerson: 'Faha',
  chatMessage: `Hello there! \n \nHow can we help?`,
  chatStatus: `Typically replies within a few minutes`,
  mainPic: '/img/main.jpg',
  mainPicButton: 'Get Your Favourites ♥',
  instagram: 'fahafoods',
  // facebook: 'fahafoods',
  // youtube: 'UCk4RFdcml5JXqRV5lx6pbkA',
  // banner: {
  //   title: 'Warning',
  //   text: 'Warning text',
  //   bottomText: 'More warning',
  // },
  workingHrs: {
    days: {
      Mon: { start: 32400000, end: 64800000 },
      Tue: { start: 32400000, end: 64800000 },
      Wed: { start: 32400000, end: 64800000 },
      Thu: { start: 32400000, end: 64800000 },
      Fri: { start: 32400000, end: 64800000 },
      Sat: { start: 32400000, end: 64800000 },
      Sun: { start: 0, end: 0 },
    },
    interval: 1800000,
  },
  aboutUs: [
    {
      header: 'Free Delivery 🚚',
      body: 'Free Delivery within 5 miles',
    },
    {
      header: 'Most Popular ⭐',
      body: "Wraps you've never tried before",
    },
    {
      header: 'Cakes 🍰',
      body: 'Best home-made cakes',
    },
  ],
  services: {
    currency: '£',
    serviceGroups: [
      {
        serviceGroupName: 'Most Popular',
        serviceItems: [
          {
            serviceName: 'Lavash',
            icon: '🌯',
            imageSrc: '/img/lavash.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',
            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSskZEUbqsroVqAV4xuidaR' : 'price_1MSskPEUbqsroVqAToMxsDFd',
          },
        ],
      },
      {
        serviceGroupName: 'Kompots',
        serviceItems: [
          {
            serviceName: 'Strawberry Kompot',
            icon: '🍓',
            imageSrc: '/img/sk.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',
            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSslAEUbqsroVqAEiQOtoMk' : 'price_1MSskzEUbqsroVqAl6lr5ZaK',
          },
          {
            serviceName: 'Pear Kompot',
            icon: '🍐',
            imageSrc: '/img/pc.jpeg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',
            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSsm2EUbqsroVqAH20OBk0F' : 'price_1MSslMEUbqsroVqA5bQp2ZZn',
          },
        ],
      },
      {
        serviceGroupName: 'Jams',
        serviceItems: [
          {
            serviceName: 'Pear Jam',
            icon: '🍐',
            imageSrc: '/img/pj.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',
            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSsmSEUbqsroVqAkBF6O4YP' : 'price_1MSsmGEUbqsroVqAErYQuVRi',
          },
          {
            serviceName: 'Apple Jam',
            icon: '🍎',
            imageSrc: '/img/aj.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',

            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSsmyEUbqsroVqAVB6CRdYu' : 'price_1MSsmfEUbqsroVqAM6fIqHTe',
          },
          {
            serviceName: 'Cucumber Jam',
            icon: '🥒',
            imageSrc: '/img/cj.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',

            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSsnOEUbqsroVqABdsqCS0q' : 'price_1MSsnEEUbqsroVqAdJwVVUtn',
          },
          {
            serviceName: 'Strawberry Jam',
            icon: '🍓',
            imageSrc: '/img/sj.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',

            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSsnqEUbqsroVqAibIPIgnJ' : 'price_1MSsnZEUbqsroVqAbUb5wDBB',
          },
        ],
      },
      {
        serviceGroupName: 'Desserts',
        serviceItems: [
          {
            serviceName: 'Eclair',
            icon: '🥯',
            imageSrc: '/img/ec.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',

            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSso7EUbqsroVqAWmo7wofc' : 'price_1MSso1EUbqsroVqADFL4CWFC',
          },
          {
            serviceName: 'Tomi Cake',
            icon: '🎂',
            imageSrc: '/img/tomicake.jpg',
            description: 'Your best 50 wraps',
            price: 50,
            duration: '00:15',

            id:
              env.REACT_APP_ENV === 'production' ? 'price_1MSsoLEUbqsroVqAtkUQdyv5' : 'price_1MSsoHEUbqsroVqAdVGd58ys',
          },
        ],
      },
    ],
  },
  reviews: [
    {
      title: 'Renovation and refit of bathroom',
      body: 'From start to finish this was the easiest and best experience we’ve had with tradesmen in London. Work finished right on time. Men on site were on time every day; worked long and hard. Always courteous and spent a lot of time making sure they kept areas as clean and tidy as possible when they left. Work itself was to a very high standard and there were no hidden extras when it came to the final bill. Estimate was detailed and accurate. Would have zero hesitation in recommending to others or using again for further work.',
    },
    {
      title: 'Refurb of whole house',
      body: 'Vugar and team are an excellent, hard working team. We were impressed at the speed, quality and communication involved in the build. Vugar also provides helpful suggestions and keeps his clients informed of changes. All in all, a very positive experience.',
    },
    {
      title: 'Bathroom refurbishment',
      body: 'The bathroom fitters, Sergiu and Eduard, were amazing - in every way! They were professional, punctual, reliable, accommodating, polite and respectful, modest, clean and tidy, and work fast. I am a perfectionist, but their workmanship is superb - impeccable! Immaculate perfection! Vugar, the director, went the extra mile to help me choose tiles for my accent wall, and I am grateful to have benefited from his expertise and trade discount. This team is delightful to work with, with first-class qualities and workmanship. They were incredible - from start to finish! My bathroom looks stunning!',
    },
    {
      title: 'Bathroom Reno / Overall Home Maintenance and Improvement',
      body: 'I have had the pleasure of working with Vugar and his teams on multiple projects this past year. The works have all been amazing, the teams always provided the highest quality of finish, showed up on time, kept the work space tidy, was very respectful of my home. Vugar is attentive, easy to contact, provides fair pricing, has amazing attention to detail, prides himself on high quality structural and aesthetic finish, is open minded and finds solutions to all my odd requests. I will contact this company again for future works without a question.',
    },
    {
      title: 'Creating new alcove in chimney stack and new ceiling lights',
      body: 'Good professional service - both elements of work were done to a high standard and to time Would definitely use again in the future for any other work needed on our property',
    },
    {
      title: 'Complete renovation of bathroom',
      body: 'Vugar and his team did an amazing job renovating our bathroom. Nothing was too much hassle and they work incredibly hard. The team are invested in getting the best results throughout the job, consulting on how to get the best finish/deal with any issues that come up efficiently. Initial quote was reliable, no slippage. Left the space spotless after the job. 10/10.',
    },
    {
      title: 'Building wardrobes and stripping',
      body: 'This is the second time we have used EV construction for building and decorating. This time they built wardrobes from scratch and fully painted them. They also stripped old wallpaper and plaster off walls and ceiling in a large living room dining room. Replastered and repainted, including painting woodwork. The work was all completed within 8 days, they arrived promptly to start work at 8am, and worked until at least 4pm every day. They pay attention to every little detail and ensure we were happy. Done to a very high standard. Will be using again and recommending to family/friends.',
    },
  ],
};

export default constants;
