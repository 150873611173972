////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { MainImage } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
// import Reviews from '../Dashboard/Reviews';
import Services from '../Dashboard/Services';
import Banner from '../Dashboard/Banner';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => (
  <>
    <MainImage
      whiteButton={false}
      imgPath={constants.mainPic}
      facebookHandle={constants.facebook}
      instagramHandle={constants.instagram}
      youtubeChannelId={constants.youtube}
      slogan={constants.slogan}
      title={constants.cust.toUpperCase()}
      onButtonClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
      buttonText={constants.mainPicButton}
      buttonBsColor='danger'
      infoTop='60%'
      imgBrightness='.3'
      imgHeight='600px'
      imgMobileHeight='220px'
      titleLetterSpacing='0.3rem'
      titleCentre={true}
      sloganCentre={true}
      mobileWidth={700}
    />
    <Banner />
    <About />
    {/* <Reviews /> */}
    <Services />
  </>
);

export default HomePage;
