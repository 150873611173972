///////////////////////////////////////////////////////////////////////////////////MODULES
import { renderToString } from 'react-dom/server';
import axios from 'axios';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants, { env } from './constants';
//////////////////////////////////////////////////////////////////////////////////////////

const sendEmail = async ({ subject, html, message, to, cc, bcc, successReply, setAlert, setLoading }) => {
  try {
    const response = await axios.post('/.netlify/functions/sendEmail', {
      host: env.REACT_APP_EMAIL_SERVER,
      user: constants.email1,
      pass: env.REACT_APP_EMAIL_PASSWORD,
      service: env.REACT_APP_EMAIL_SERVICE,
      from: { name: constants.cust, address: constants.email1 },
      to,
      cc,
      bcc,
      subject,
      message,
      html: renderToString(html),
      successReply,
    });
    setAlert(response.data, false, null, null);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    setAlert(e.message, true, null, null);
  }
};

export default sendEmail;
