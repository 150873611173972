///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { msToHm } from '@ibiliaze/time';
import { CustomNav, Offcanvas, OffcanvasHeader, OffcanvasBody, Table } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Wishlist from '../Wishlist/Wishlist';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ wishlist }) => {
  // State
  const [wishlistModal, setWishlistModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);

  // Toggle functions
  const toggleWishlistModal = () => setWishlistModal(!wishlistModal);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Navigate
  const navigate = useNavigate();

  // onClick functions
  const onWishlistClick = () => toggleWishlistModal();

  // JSX
  return (
    <>
      <CustomNav
        dark
        navbarBrand={constants.cust}
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        navlinks={[
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('about-section')?.offsetTop);
            },
            hide: false,
          },
          {
            title: 'Services',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('services-section')?.offsetTop);
            },
            hide: false,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => {
              navigate('/');
              window.scrollTo(0, document.body.scrollHeight);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          // guest links
          {
            title: `Basket ♥ ${wishlist.items.length}`,
            href: '#',
            onClick: _ => onWishlistClick(),
            hide: false,
            badge: true,
            badgeColour: 'danger',
          },
          // {
          //   title: 'Visit us',
          //   href: '#',
          //   onClick: toggleOffcanvas,
          //   hide: false,
          // },
        ]}
      />
      <Offcanvas direction='end' fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Working Hours</OffcanvasHeader>
        <OffcanvasBody>
          <Table borderless className='wishlist-table'>
            <tbody>
              {Object.keys(constants.workingHrs.days).map((dow, i) => {
                const wh = constants.workingHrs.days[dow];
                return (
                  <tr key={i}>
                    <th scope='row'>{dow}</th>
                    <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.start)}</td>
                    <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.end)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </OffcanvasBody>
      </Offcanvas>
      <Wishlist modal={wishlistModal} toggle={toggleWishlistModal} />
    </>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist });

export default connect(mapStateToProps)(Header);
